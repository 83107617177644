import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { ContactoService } from '../../services/contacto.service';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.css']
})
export class UnsubscribeComponent implements OnInit {

  public correoBaja: string = '';
  public serviciosBaja: string = '';
  private servicios: string = '';

  constructor(private route: ActivatedRoute, private contactoService: ContactoService) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.correoBaja = params.correo;
        this.servicios = params.seccion || params["amp;seccion"];
        this.serviciosBaja = this._obtenerSeccion(this.servicios);
      });

      if (this.correoBaja !== null && this.correoBaja !== '' && this.serviciosBaja !== 'No se ha indicado ninguna sección')
      {
        this.contactoService.procesarBajaCorreo( this.correoBaja, this.servicios )
          .subscribe( resp => {
  
            if(resp.Resultado == 'OK')
              Swal.fire('Hemos registrado correctamente su petición de baja, en 24h - 48h  se procesará.' );
            else
              Swal.fire('Se ha producido algún error en la petición le rogamos nos llame para solucionarlo.' );
  
            // Navegar al Dashboard
            //this.router.navigateByUrl('/');
  
          }, (err) => {
            // Si sucede un error
            Swal.fire('Error', err.error.msg, 'error' );
          });
      } else {
        Swal.fire('Error', 'Tiene que especificar el correo y la sección a la que quiere darse de baja', 'error');
      }


  }

  _obtenerSeccion(seccion: string): string {
    switch (seccion) {
      case 'envio_duplicado_factura':
        return 'Enviar duplicado de factura';
      case 'contabilidad':
        return 'Enviar información contable';
      case 'extranet':
        return 'Información sobre la extranet';
      case 'notificacion_servicio_realizado':
        return 'Notificación servicio realizado';
      case 'enviar_certificado_email':
        return 'Enviar copia del certificado por e-mail';
      case 'informacion_plagas':
        return 'Enviar información sobre las plagas de temporada';
      case 'informacion_comercial':
        return 'Enviar información sobre Servicios';
      case 'all':
        return 'Todas las secciones ';
      default:
        return 'No se ha indicado ninguna sección';
  
    }
  } 
}