import { Component, OnInit, ViewChild } from '@angular/core';
import { IActionMapping, ITreeOptions, KEYS, TreeComponent, TreeModel, TreeNode, TREE_ACTIONS } from '@circlon/angular-tree-component';
import { ExtranetService } from '../../services/extranet.service';
import { DocumentoDescarga } from '../../models/documento-descarga.model';


@Component({
  selector: 'app-documentos-cliente',
  templateUrl: './documentos-cliente.component.html',
  styleUrls: ['./documentos-cliente.component.css']
})

export class DocumentosClienteComponent implements OnInit {
  @ViewChild(TreeComponent) private docs: TreeComponent | undefined;

  nodes: any[] = [];

  options: ITreeOptions = { 
    actionMapping: {
      mouse: {
        click: (tree: TreeModel, node: TreeNode, $event: any) => {
          if (node.hasChildren) { TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event); }
    
          if (node.data.doc > 0) { 
            // alert(`Descargar documento ${node.data.doc }`); 
            this.showDocument( Number(node.data.doc) );
          }
        }
      },
      keys: {
        [KEYS.ENTER]: (tree: TreeModel, node: TreeNode, $event: any) => {
    
          if (node.hasChildren) { TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event); }
    
          if (node.data.doc > 0) { alert(`Descargar documento ${node.data.doc }`); }
          }
      }
    }
  };
  
  contador = 0;

  constructor( private extranetService: ExtranetService) { }

  ngOnInit(): void {
    this.extranetService.getClientDocuments().subscribe(
      (data) => {
        this.nodes = this.convertDocsIntoNodes(data);
        this.docs?.treeModel.update();
    });
  }

  showDocument(idDocumento: number) {
    this.extranetService.getDocument(idDocumento)
    .subscribe((doc: DocumentoDescarga) => {
      if(doc.Paginas != null && doc.Paginas?.length > 0) {
        if(doc.Paginas[0].ArchivoDocumento != null) {
          
          // const pdfInBase64 = atob(doc.Paginas[0].ArchivoDocumento);
          // const newBlob = new Blob([pdfInBase64], {type: 'application/pdf'});
          // const linkElement = document.createElement('a');
          // const url = URL.createObjectURL(newBlob);
          // linkElement.setAttribute('href', url);
          // linkElement.setAttribute('download', doc.NombreArchivo ?? 'documento.pdf');
          // const clickEvent = new MouseEvent('click', {
          //   'view': window,
          //   'bubbles': true,
          //   'cancelable': false
          // });
          // linkElement.dispatchEvent(clickEvent);

          const linkSource = 'data:application/pdf;base64,' + doc.Paginas[0].ArchivoDocumento;
          const downloadLink = document.createElement("a");
          const fileName = doc.NombreArchivo ?? 'documento.pdf';
  
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    });
  }

  convertDocsIntoNodes( data: { Id: number, Provincia: string, Poblacion: string, Centro: string, Documento: string}[]): any[]{

    const docs: object[] = [];
    const provincias: string[] = this.extraerProvincias(data);

    provincias.forEach(provincia => {
      const docsProvincia = data.filter(item => item.Provincia === provincia);
      const docsPoblacion = this.convertDocsIntoPoblacionNodes(docsProvincia);
      this.contador++;
      const prov = `${provincia} ${this.contador}`;
      docs.push({id: this.contador, name: provincia, children: docsPoblacion});
    });

    return docs;
  }

  convertDocsIntoPoblacionNodes(data: { Id: number, Provincia: string, Poblacion: string, Centro: string, Documento: string}[]): any[]{

    const docs: object[] = [];
    const poblaciones: string[] = this.extraerPoblaciones(data);

    poblaciones.forEach(poblacion => {
      const docsPoblacion = data.filter(item => item.Poblacion === poblacion);
      const docsCentro = this.convertDocsIntoCentroNodes(docsPoblacion);
      docs.push({id: this.contador, name: poblacion, children: docsCentro});
      this.contador++;
    });
    
    return docs;
  }

  convertDocsIntoCentroNodes(data: { Id: number, Provincia: string, Poblacion: string, Centro: string, Documento: string}[]): any[]{

    const docs: object[] = [];
    const centros: string[] = this.extraerCentros(data);

    centros.forEach(centro => {
      const docsCentro = data.filter(item => item.Centro === centro);
      const docsDocumento = this.convertDocsIntoDocumentoNodes(docsCentro);
      docs.push({id: this.contador, name: centro, children: docsDocumento});
      this.contador++;
    });
    
    return docs;
  }

  convertDocsIntoDocumentoNodes(data: { Id: number, Provincia: string, Poblacion: string, Centro: string, Documento: string}[]): any[]{

    const docs: object[] = [];

    data.forEach(doc => {
      docs.push({id: this.contador, name: doc.Documento, doc: doc.Id});
      this.contador++;
    });
    
    return docs;
  }

  extraerProvincias(data: { Id: number, Provincia: string, Poblacion: string, Centro: string, Documento: string}[]): string[] {
    const provincias: string[] = [];

    data.forEach(doc => {
      if (!provincias.includes(doc.Provincia)){
        provincias.push(doc.Provincia);
      }
    });

    return provincias;
  }

  extraerPoblaciones(data: { Id: number, Provincia: string, Poblacion: string, Centro: string, Documento: string}[]): string[] {
    const poblaciones: string[] = [];

    data.forEach(doc => {
      if (!poblaciones.includes(doc.Poblacion)){
        poblaciones.push(doc.Poblacion);
      }
    });

    return poblaciones;
  }

  extraerCentros(data: { Id: number, Provincia: string, Poblacion: string, Centro: string, Documento: string}[]): string[] {
    const centros: string[] = [];

    data.forEach(doc => {
      if (!centros.includes(doc.Centro)){
        centros.push(doc.Centro);
      }
    });

    return centros;
  }

}

