import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { RequestCallForm } from '../interfaces/request-call-form.interface';

const base_url = environment.base_url;

@Injectable({
    providedIn: 'root'
  })
  export class ContactoService {

    constructor(
      private http: HttpClient,
      private router: Router,
      private ngZone: NgZone ) {}

    solicitarLlamada( formData: RequestCallForm ): Observable<any> {

      const postData = {
        Filtros: '',
        Objeto: formData.nombre + ':' + formData.telefono,
        Operacion: 'Servicios.Extranet.Contacto.Llamada',
        Token: ''
      };

      return this.http.post(`${ base_url }/api/extranet`, postData )
                .pipe(
                  tap( (resp: any) => {
                    // console.log(resp);
                  })
                );
    
              }

    procesarBajaCorreo( correo: string, seccion: string ): Observable<any> {
      const postData = {
        Filtros: '',
        Objeto:`${correo}:${seccion}`,
        Operacion: 'Servicios.Extranet.GestionarEmail.ProcesarBajaServicioCorreo',
        Token: ''
      };

      return this.http.post(`${ base_url }/api/extranet`, postData )
                .pipe(
                  tap( (resp: any) => {
                    // console.log(resp);
                  })
                );
    
              }
}
