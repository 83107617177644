<footer class="footer">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="col">

                            <a href="/assets/images/pages/programa_ecovul_2022.jpg" target="_blank">
                                <img src="/assets/images/pages/programa_ecovul_2022_thumb.jpg" alt="Programa Ecovul 2022" height="175px"/>
                            </a>
                            <div class="subvencion">
                                Concedido por: LABORA Servicio Valenciano de Empleo y Formación<br>
                                Importe percibido: 25.200 €<br>
                                Finalidad: Fomento de la contratación indefinida<br>
                                Beneficiario: Gar-San Control de Plagas, S.L.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row">
                        <div class="col">

                            <a href="/assets/images/pages/programa_ecovul_2023.jpg" target="_blank">
                                <img src="/assets/images/pages/programa_ecovul_2023_thumb.jpg" alt="Programa Ecovul 2023" height="175px"/>
                            </a>
                            <div class="subvencion">
                                Concedido por: LABORA Servicio Valenciano de Empleo y Formación<br>
                                Importe percibido: 27.216 €<br>
                                Finalidad: Fomento de la contratación indefinida<br>
                                Beneficiario: Gar-San Control de Plagas, S.L.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row"><a routerLink="/empresa/aviso-legal">Aviso Legal</a></div>
                    <div class="row"><a routerLink="/empresa/politica-privacidad">Política de privacidad</a></div>
                    <div class="row"><a routerLink="/empresa/politica-cookies">Política de cookies</a></div>
                    <div class="row"><a routerLink="/empresa/politica-datos">Información Básica y detallada en protección de datos</a></div>
                    <div class="row"><a routerLink="/empresa/condiciones-uso">Condiciones de Uso</a></div>
                </div>
                <div class="col">
                    <div class="row">GAR-SAN Control de plagas, S.L.</div>
                    <div class="row">C/ Pintor Sorolla, 28</div>
                    <div class="row">03550 Sant Joan d'Alacant</div>
                    <div class="row">Alicante</div>
                    <div class="row" id="pie_telefono_contacto"><a [href]="'tel:965658302'">96.565.83.02</a></div>
                    <div class="row" id="pie_horario_telefono">(L-V 09:00-15:00)</div>
                    <div class="row" id="pie_email_contacto"><a [href]="'mailto:ddd@gar-san.com'">ddd@gar-san.com</a></div>
                </div>
                <div class="col">
                    <p>© 2023 Gar-San</p>
                    <p><small>Background Photo by Daniele Levis Pelusi on Unsplash<br>
                    <a target="_blank" href="https://icons8.com/icon/43301/agitar-teléfono">Agitar teléfono</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a><br>
                    <a target="_blank" href="https://icons8.com/icon/53889/instagram">Instagram</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a></small></p>
                </div>
            </div>

        </div>
    </div>
</footer>
<!-- <div class="alert alert-warning grdp" role="alert" [hidden]="!visible">
    <p>
        <strong>RGPD (Reglamento General Protección de Datos) - GDPR (General Data Protection Regulation -)</strong>
        <br>
        Utilizamos cookies propias y de terceros para mejorar nuestros servicios y mostrarte publicidad relacionada con tus preferencias mediante el análisis de tus hábitos de navegación.
        Puede obtener más información en nuestra <a routerLink="/empresa/politica-cookies">Política de Cookies.</a>
        Pulse el botón ACEPTAR, para confirmar que ha leído y aceptado la información presentada. Después de aceptar no volveremos a mostrar este mensaje.
    </p>
    <button type="button" class="btn btn-success bcgrdp" (click)="onGRDP()">Aceptar</button>
</div> -->