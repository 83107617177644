<h2>Instrucciones de uso</h2>
<p>En esta extranet puede tener acceso a los documentos relativos a los servicios prestados por Gar-San Sanidad Ambiental en sus instalaciones</p>
<p>En el apartado <a routerLink="/extranet/documentos-cliente">Gestor documental</a> están todos los certificados de los servicios realizados, informes y planos de la instalación</p>
<p>En la opción <a routerLink="/extranet/documentos-cliente">Fichas de productos usados</a> Se pueden consultar todos los registros y fichas de seguridad de los productos que se han usado en sus instalaciones</p>
<p>Por otro lado en la sección de <a routerLink="/extranet/documentos-garsan">Documentos generales Gar-San</a> están accesibles tanto los documentos relativos a la empresa, como los relacionados con los técnicos que han prestado los servicios. Entre otros están el ROESB, Certificados de ISO y los
    carnés / certificados de los técnicos</p>
<!-- <h2>Dashboard</h2>
<div class="row">
    <div class="col">Documentos actualizados</div>
    <div class="col">Servicios Realizados</div>
    <div class="col">Informes</div>
</div> -->
<br>