import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  public menu: any = [];

  cargarMenu(): void {

    this.menu = [
        {
          titulo: 'Empresa',
          icono: 'mdi mdi-gauge',
          submenu: [
              { titulo: 'Quiénes somos', url: '/' },
              { titulo: 'Cobertura Nacional', url: '/empresa' },
            ]
          },
          {
            titulo: 'Control de Plagas',
            icono: 'mdi mdi-folder-lock-open',
            submenu: [
              { titulo: 'Ratas', url: '/empresa/control-plagas' },
              { titulo: 'Ratones', url: '/empresa/control-plagas' },
              ]
          },
          {
            titulo: 'Tto. Madera',
            icono: 'mdi mdi-folder-lock-open',
            submenu: [
              { titulo: 'Termitas', url: '/empresa/tratamientos-madera' },
              { titulo: 'Carcoma', url: '/empresa/tratamientos-madera' },
              { titulo: 'Hongos', url: '/empresa/tratamientos-madera' },
              ]
          },
          ];
  }
}
