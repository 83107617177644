import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aves-urbanas',
  templateUrl: './aves-urbanas.component.html',
  styleUrls: ['./aves-urbanas.component.css']
})
export class AvesUrbanasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
