<div class="login-box card">
    <div class="card-body">
        <form class="form-horizontal form-material" 
                autocomplete="off"
                [formGroup]="loginForm"
                (ngSubmit)="login()"
                id="loginform">

            <a href="javascript:void(0)" class="text-center db"><img src="../assets/images/logo-icon.png" alt="Home" /><br/><img src="../assets/images/logo-text.png" alt="Home" /></a>
            <div class="form-group mt-5">
                <div class="col-xs-12">
                    <input class="form-control" 
                            type="text" 
                            placeholder="Usuario"
                            formControlName="user">
                </div>
            </div>
            <div class="form-group">
                <div class="col-xs-12">
                    <input class="form-control" 
                            type="password"
                            placeholder="Pass"
                            formControlName="pass">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-12">
                    <div class="checkbox checkbox-primary pull-left p-t-0">
                        <input id="checkbox-signup" 
                                type="checkbox" 
                                class="filled-in chk-col-light-blue"
                                formControlName="remember">
                        <label for="checkbox-signup"> Recordarme </label>
                    </div>
                    <a href="javascript:void(0)" id="to-recover" class="text-dark pull-right"><i class="fa fa-lock m-r-5"></i> ¿Olvidaste la contraseña?</a> </div>
            </div>
            <div class="form-group text-center mt-5">
                <div class="col-xs-12">
                    <button class="btn btn-info btn-lg btn-block text-uppercase btn-rounded" type="submit">Conectar</button>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 mt-5 mb-4 text-center" id="google-btn">
                    
                    <div id="my-signin2"></div>

                </div>
            </div> -->
            <!-- <div class="form-group m-b-0">
                <div class="col-sm-12 text-center">
                    Don't have an account? <a routerLink="/register" class="text-primary m-l-5"><b>Sign Up</b></a>
                </div>
            </div> -->
        </form>
        <form class="form-horizontal" id="recoverform" action="index.html">
            <div class="form-group ">
                <div class="col-xs-12">
                    <h3>Recuperar password</h3>
                    <p class="text-muted">Introduce tu usuario y te enviaremos un email con las instrucciones! </p>
                </div>
            </div>
            <div class="form-group ">
                <div class="col-xs-12">
                    <input class="form-control" type="text" required="" placeholder="Usuari">
                </div>
            </div>
            <div class="form-group text-center mt-5">
                <div class="col-xs-12">
                    <button class="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Reset</button>
                </div>
            </div>
        </form>
    </div>
</div>
