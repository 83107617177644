import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coronavirus',
  templateUrl: './coronavirus.component.html',
  styleUrls: ['./coronavirus.component.css']
})
export class CoronavirusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
