<div class="col-md py-2 d-flex justify-content-center">
    <div class="row">
        <div class="social_logo">
            <a href="http://facebook.com/GarsanCP" target="_blank"><img src="/assets/icons/blue-icon-facebook.svg" alt="Facebook: @GarsanCP" /></a>
        </div>
        <div class="social_logo">
            <a href="https://www.instagram.com/GarsanCP" target="_blank"><img src="/assets/icons/blue-icon-instagram.svg" alt="Instagram: gar-san-sanidad-ambiental" /></a>
        </div>
    </div>
    <div class="row">
        <div class="social_logo">
            <a href="https://www.linkedin.com/company/gar-san-sanidad-ambiental" target="_blank"><img src="/assets/icons/blue-icon-linkedin.svg" alt="Linkedin: gar-san-sanidad-ambiental" /></a>
        </div>
        <div class="social_logo">
            <a href="https://wa.me/+34687730024" target="_blank"><img src="/assets/icons/blue-icon-whatsapp.svg" alt="Linkedin: gar-san-sanidad-ambiental" /></a>
        </div>
    </div>
</div>