<h1>Política integrada de calidad y medioambiente</h1>
<p>El alcance del Sistema de Gestión es: Establecimiento de actuaciones para la desratización, desinsectación, desinfección, control de aves urbanas y tratamiento de aguas.</p>
<p>El presente Sistema de Gestión tiene como finalidad la satisfacción de las partes interesadas de Gar-San Sanidad Ambiental, tomando como base el cumplimiento de los requisitos establecidos en los plazos y precios fijados, así como la mejora medioambiental continua y constante que se cumplirá minimizando los impactos ambientales significativos de las actividades de la empresa Para ello las pautas principales de la política de Gar-San Sanidad Ambiental, son las siguientes:</p>
<a name="pautas_comunes"><h2>Pautas comunes</h2></a>
<ul>
    <li>La dirección de Gar-San Sanidad Ambiental, se compromete a cumplir y a poner a disposición de su personal todos los medios necesarios para que se cumplan los requisitos de las norma UNE-EN ISO 9001 y UNE-EN ISO 14001.</li>
    <li>En el cumplimiento de nuestros objetivos, observaremos permanentemente las siguientes aptitudes distintivas para satisfacer a nuestros clientes.
        <ol>
            <li>Amabilidad en el servicio y agilidad en los procesos.</li>
            <li>Innovación práctica y eficiencia en costos.</li>
            <li>Compromiso con las normas de calidad y medio ambiente.</li>
        </ol>
    </li>
    <li>Nuestra política de gestión se manifiesta mediante nuestro firme compromiso con los Clientes de satisfacer plenamente sus requerimientos y expectativas, para ello garantizamos impulsar una cultura de calidad y medio ambiente basada en los principios de honestidad, liderazgo y desarrollo del recursos humanos, solidaridad, compromiso de mejora y seguridad en nuestras operaciones.</li>
</ul>
<a name="pautas_calidad"><h2>Pautas de Calidad</h2></a>
<ul>
    <li>Mejorar continuamente la eficacia tanto del Sistema de Gestión, como de las características de los servicios ofertados.</li>
    <li>Establecer y revisar unos objetivos y metas (junto con sus programaciones) medibles y coherentes con esta política.</li>
    <li>Realizar revisiones periódicas tanto del sistema como de la política con el fin de asegurar su correcta adecuación.</li>
    <li>Satisfacer los requisitos legales y reglamentarios que sean aplicables al producto y cumplir toda la legislación y todas las normas y códigos relacionados con nuestro impacto medioambiental.</li>
    <li>Una atención dentro del plazo descrito en el contrato.</li>
    <li>La utilización de productos registrados y materiales debidamente homologados para servicio eficaz y responsable.</li>
    <li>La formación continuada de métodos, productos y sistemas de trabajo para optimizar las labores en el campo de aplicación y la gestión medioambiental.</li>
</ul>
<a name="pautas_medioambiente"><h2>Pautas de Medioambiente</h2></a>
<ul>
    <li>Gar-San Sanidad Ambiental se compromete a la protección y mejora del medio ambiente. La mejora de la actuación medioambiental general de la empresa es una prioridad principal y continua que se espera conseguir mediante la implantación y mantenimiento de un sistema de gestión integrado y el cumplimiento de esta política.</li>
    <li>Gar-San Sanidad Ambiental se esfuerza por minimizar el impacto medioambiental de todas sus actividades, productos y procesos. En concreto se pretende:
        <ul>
            <li>Minimizar el impacto ambiental de residuos de envases.</li>
            <li>Prevenir la contaminación en superficies, suelos y agua.</li>
            <li>Minimizar el impacto ambiental de los residuos generados en los procesos administrativos (RAEE´s, pilas, papel, tóner, etc.).</li>
            <li>Minimizar el consumo de combustible en los vehículos de la empresa.</li>
            <li>Reducir el consumo energético y de agua en la oficina.</li>
        </ul>
    </li>
    <li>Para lograr esta mejora medioambiental de la empresa, Gar-San Sanidad Ambiental ha establecido, mantiene, revisa y modifica en caso de ser necesario, los objetivos y metas medioambientales con la finalidad de mejorar continuamente nuestra gestión medioambiental.</li>
    <li>Así mismo, Gar-San Sanidad Ambiental se compromete a que todos los productos, procesos y propuestas de inversión serán examinados antes de su aprobación para evaluar sus posibles impactos medioambientales.</li>
    <li>En Gar-San Sanidad Ambiental se mantendrá el diálogo para asegurar que todos los empleados, proveedores y subcontratistas son conscientes de la política medioambiental y que participan en el trabajo medioambiental.</li>
</ul>