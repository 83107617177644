<h1>Legionella y tratamientos de agua</h1>
<section id="legionela">
    <h2>Legionella</h2>
    <img src="/assets/images/pages/foto_circuito_hidraulico.jpg" class="floatright" width='25%'>
    <p>La empresa Gar-San Sanidad Ambiental desarrolla trabajos de prevención y control de legionella en:</p>
    <ol>
        <li>Sistemas de agua sanitaria.</li>
        <li>Torres de refrigeración y condensadores evaporativos.</li>
        <li>Equipos de enfriamiento evaporativo.</li>
        <li>Centrales humidificadoras industriales.</li>
        <li>Humidificadores.</li>
        <li>Sistemas de agua contra incendios.</li>
        <li>Sistemas de agua climatizada o con temperaturas similares a las climatizadas (≥ 24 ºC) y aerosolización con/sin agitación y con/sin recirculación a través de chorros de alta velocidad o la inyección de aire, vasos de piscinas polivalente con este tipo de instalaciones, vasos de piscinas con dispositivos de juego, zonas de juegos de agua, setas, cortinas, cascadas, entre otras.</li>
        <li>Fuentes ornamentales con difusión de aerosoles y fuentes transitables.</li>
        <li>Sistemas de riego por aspersión en el medio urbano o en campos de golf o deportes.</li>
        <li>Dispositivos de enfriamiento evaporativo por pulverización mediante elementos de refrigeración por aerosolización.</li>
        <li>Sistemas de lavado de vehículos.</li>
        <li>Máquinas de riego o baldeo de vías públicas y vehículos de limpieza viaria.</li>
        <li>Cualquier elemento destinado a refrigeración y/o humectación susceptible de producir aerosoles no incluido en el resto de puntos.</li>
        <li>Instalaciones de uso sanitario / terapéutico: Equipos de terapia respiratoria; respiradores; nebulizadores; sistemas de agua a presión en tratamientos dentales; bañeras terapéuticas con agua a presión; bañeras obstétricas para partos e instalaciones que utilicen aguas declaradas mineromedicinales o termales.</li>
        <li>Cualquier otra instalación que utilice agua en su funcionamiento y produzca o sea susceptible de producir aerosoles que puedan suponer un riesgo para la salud de la población.</li>
    </ol>
    <p>Dichos trabajos en las instalaciones anteriormente mencionadas comprenden labores de:</p>
    <ul>
        <li>Censado.</li>
        <li>Evaluación del riesgo según guía del Ministerio de Sanidad.</li>
        <li>Asesoramiento técnico (creación del plan de prevención y control de legionella <b>PPCL</b>, revisiones, instrucciones de trabajo, información en general, etc.).</li>
        <li>Limpiezas y desinfección de equipos de riesgo según el RD487/2022 que establece requisitos higiénico-sanitarios necesarios para la prevención y el control de legionelosis.</li>
        <li>Gestión y recogida de analíticas de legionella (acreditado por ENAC).</li>
        <li>Paneles de Control y dosificación (Cloro, pH, conductividad, temperatura).</li>
    </ul>    
</section>
<section id="analiticas">
    <h2>Análisis Aguas</h2>
    <ul>
        <li>Análisis de legionella en todo tipo de aguas por parte de laboratorio acreditado por ENAC (Real Decreto 487/2022).</li>
        <li>Análisis físico-químicos y microbiológicos en aguas de consumo humano (Real Decreto 140/2003) y aguas de proceso.</li>
        <li>Análisis aguas residuales o de vertidos según ordenanzas municipales o entidades autonómicas como EPSAR.</li>
        <li>Análisis de piscinas según Real Decreto 742/2013 y Decreto 85/2018.</li>
    </ul>
    <h2>Análisis de alimentos y superficies</h2>
    <ul>
        <li>Análisis de bacterias en alimentos (E. coli, salmonella, listeria, etc.).</li>
        <li>Análisis de bacterias en superficies de contacto (aerobios, enterobacterias, listeria, etc.).</li>
    </ul>
    <h2>Control Ambiente de Interiores</h2>
    <ul>
        <li>Muestreo y análisis en ambientes interiores (bacterias aerobias, hongos, etc.).</li>
    </ul>
</section>
<section id="dosificadores">
    <h2>Dosificadores automáticos (Agua potable y Agua industrial)</h2>
    <img src="/assets/images/pages/foto_panel.jpg" class="floatright" width='15%'>
    <ul>
        <li>Realizamos el diseño, el montaje y la puesta en marcha de dosificadores automáticos y semiautomáticos de productos químicos a la medida de cada instalación.</li>
        <li>Amplia disponibilidad de parámetros a analizar: cloro, bromo, pH, temperatura, conductividad, etc.</li>
        <li>Posibilidad de conexión del dosificador con PC, móvil o tablet vía módem GSM/GPRS.</li>
        <li>Servicio de mantenimiento y reparación de equipos dosificadores.</li>
    </ul>
</section>
<section id="limpieza_depositos">
    <h2>Limpieza de depósitos</h2>
    <p>Realizamos desinfecciones del sistema hidráulico de diferentes establecimientos hoteleros.</p>
    <p>Se procede a una desinfección completa de los sistemas de agua potable de consumo mediante una hipercloración del agua del sistema, cumpliendo de esta forma con la normativa señalada en la Orden del 7 de Mayo de 1.986, de la Consellería de Sanidad y Consumo,
        por las que se determinan las normas mínimas de tratamiento sanitario de los citados establecimientos, y en el Real Decreto 487/2022 de 21 junio de 2022.</p>
    <p>También disponemos de DESCALCIFICADORES y tratamiento de aguas por ÓSMOSIS INVERSA</p>
</section>
<section id="piscinas">
    <h2>Piscinas</h2>
    <img src="/assets/images/pages/foto_piscina.jpg" class="floatright" width='25%'>
    <ul>
        <li>Asesoramiento y elaboración del protocolo de autocontrol de piscinas según normativa R.D. 742/2013 de piscinas.</li>
        <li>Análisis de parámetros de agua de piscina según Real Decreto 742/2013 y normativas autonómicas.</li>
        <li>Asesoramiento y elaboración del protocolo de autocontrol de piscinas según normativa R.D. 742/2013 de piscinas.</li>
        <li>Venta de productos químicos para piscina: cloro (hipoclorito sódico), reguladores de pH, antialgas, etc.</li>
        <li>Venta de medidores digitales de cloro, pH, turbidez, CO2, etc.</li>
        <li>Mantenimiento y reparación de equipos dosificadores.</li>
    </ul>
</section>