import { Component} from '@angular/core';

@Component({
  selector: 'app-header-callback',
  templateUrl: './header_callback.component.html',
  styleUrls: ['./header_callback.component.css']
})
export class HeaderCallbackComponent {

  constructor( 
    ) {
  }

}
