import { environment } from '../../environments/environment';

const base_url = environment.base_url;

export class Usuario {

    constructor(
        public nombre: string,
        public email: string,
        public password?: string,
        public token?: string,
        public id?: number,
        public idCliente?: number,
        public idClienteFacturacion?: number,
        public zonaCliente?: string,
        public idClienteServicio?: number,
        public idIntermediario?: number,
    ) {}

}

