import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modulos
import { SharedModule } from '../shared/shared.module';
import { ComponentsModule } from '../components/components.module';

import { PipesModule } from '../pipes/pipes.module';

// Components
import { PagesComponent } from './pages.component';
import { EmpresaComponent } from './empresa/empresa.component';
import { ControlPlagasComponent } from './control-plagas/control-plagas.component';
import { ControlMoscasMosquitosComponent } from './control-moscas-mosquitos/control-moscas-mosquitos.component';
import { CoronavirusComponent } from './coronavirus/coronavirus.component';
import { TratamientosMaderaComponent } from './tratamientos-madera/tratamientos-madera.component';
import { TratamientosAguaComponent } from './tratamientos-agua/tratamientos-agua.component';
import { IndustriaAlimentariaComponent } from './industria-alimentaria/industria-alimentaria.component';
import { ContactoComponent } from './contacto/contacto.component';
import { CalidadMedioambienteComponent } from './calidad-medioambiente/calidad-medioambiente.component';
import { TrabajosAlturaComponent } from './trabajos-altura/trabajos-altura.component';
import { ProcesionariaComponent } from './procesionaria/procesionaria.component';
import { AvesUrbanasComponent } from './aves-urbanas/aves-urbanas.component';
import { LoginComponent } from './login/login.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';


@NgModule({
  declarations: [
    PagesComponent,
    EmpresaComponent,
    ControlPlagasComponent,
    ControlMoscasMosquitosComponent,
    CoronavirusComponent,
    TratamientosMaderaComponent,
    TratamientosAguaComponent,
    IndustriaAlimentariaComponent,
    ContactoComponent,
    CalidadMedioambienteComponent,
    TrabajosAlturaComponent,
    ProcesionariaComponent,
    AvesUrbanasComponent,
    LoginComponent,
    UnsubscribeComponent
  ],
  exports: [
    EmpresaComponent,
    PagesComponent,
    ControlPlagasComponent,
    ControlMoscasMosquitosComponent,
    CoronavirusComponent,
    TratamientosMaderaComponent,
    TratamientosAguaComponent,
    IndustriaAlimentariaComponent,
    ContactoComponent,
    CalidadMedioambienteComponent,
    TrabajosAlturaComponent,
    ProcesionariaComponent,
    AvesUrbanasComponent,
    LoginComponent,
    UnsubscribeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    ComponentsModule,
    PipesModule
  ]
})
export class PagesModule { }
