<h1>D.D.D. Desratización, Desinsectación y Desinfección</h1>
<section id="ratas_ratones">
    <h2>Desratización, control de roedores (ratas y ratones)</h2>
    <p>Realizamos tratamientos en industrias, oficinas, organismos públicos, viviendas, etc., ya que siempre es posible la aparición de los roedores con los problemas que estos conllevan.</p>
    <p>En nuestra empresa llevamos años tratando estos problemas con garantías. Trabajamos con las marcas más destacadas y productos presentados en distintos formatos: granulados, gel, pastillas, polvos, etc., pensados para actuar satisfactoriamente sea cual sea
        su caso y el lugar donde aplicarlos.</p>
    <p>Todos los compuestos utilizados están homologados por Salud Pública para su utilización en alimentación, ganadería, así como zonas habitadas.</p>
    <p>El personal técnico le informará y asesorará del tratamiento y medidas a seguir en el momento de la visita, quedando posteriormente a su disposición para consultas.</p>
</section>
<section id="desinsectacion">
    <h2>Desinsectación, control de insectos (cucarachas, hormigas, pulgas, garrapatas, polillas, chinches, moscas, carcomas, avispas, procesionaria)</h2>
    <img src='/assets/images/pages/foto_cucarachas.jpg' width="10%" class="floatright">
    <p>Nuestra empresa realiza un amplio servicio en tratamientos de Desinsectación, abarcando gran variedad de insectos considerados plagas, con diversos sistemas de operación a la hora de efectuar dichos servicios, adaptándonos a sus necesidades, las de
        su negocio o su vivienda, utilizando productos de última generación que respetan y cuidan el medio ambiente.</p>
    <p>El personal técnico altamente cualificado determinará junto con Ud. el modo y forma de actuar en su caso particular.</p>
    <p>Insectos considerados plagas que actualmente tratamos:</p>
    <ul>
        <li>Cucarachas (Todas las especies). Tratamientos con GEL SIN plazo de seguridad.</li>
        <li>Hormigas. Tratamientos con GEL SIN plazo de seguridad.</li>
        <li>Chinches. Tratamiento sin plazo de seguridad con vapor seco y diatomeas</li>
        <li>Moscas, mosquitos. Registros de contaje y control mediante lámparas UV y feromonas sexuales. <a routerLink="/empresa/control-moscas-mosquitos">Ampliar información</a></li>
        <li>Carcomas, termitas y toda clase de hongos e insectos xilófagos. Tratamientos con microondas, imprimación e inyección, entre otros.</li>
        <li>Avispas e insectos voladores. Captura a través de atrayentes específicos.</li>
        <li>Abejas. Están protegidas según la Ley 42/2007 en el caso de la fracción silvestre de la abeja melífera y para la fracción doméstica el Real Decreto 479/2004, Ley 8/2003 y Real Decreto 209/2002 por lo que no podemos tratarlas. Ante cualquier problema
            tienen que contactar con la Asociación Nacional de Apicultores (ANAE) (96.219.11.75). Si la citada asociación indica que no pueden hacerse cargo de la colmena el siguiente paso es llamar al 112 y solicitar una retirada del panal por parte de los
            bomberos.
        </li>
        <li>Procesionaria, orugas y plagas localizadas en arboles. Tratamiento preventivo mediante Bacilus Thuringiensis (respetuoso con el medio ambiente) e inyección en tronco y aplicaciones fliares en la copa, así como trampas de feromonas para la captura de los adultos. <a routerLink="/empresa/procesionaria">Ampliar información</a></li>
        <li>Pulgas, garrapatas. Aunque el origen de estos insectos es en el 99% de los casos proviniente de animales urbanos o de granja, una vez desinsectados los animales origen es conveniente realizar tratamientos para eliminar rápidamente cualquier insecto perjudicial que pudiese quedar.</li>
        <li>Polillas. Control mediante atrayantes sexuales.</li>
        <li>IPA (Insectos producto almacenado). También realizamos tratamientos, entre otros, contra Tribolium de harina (<em>Tribolium sp.</em>), Piojillo del jamón (<em>Tyrophagus sp.</em>), Palomilla del chocolate (<em>Ephestia sp. / Plodia</em>), etc con termodifusores y trampas de monitoreo, sin peligro para el posterior consumo de la materia prima.</li>
    </ul>
    <h3>Desinsectación Atmosférica:</h3><p>Eficaz solución a problemas de insectos voladores, piojos y ácaros en general mediante una potente acción de choque, eliminando los insectos que se encuentran en el local durante su tratamiento. Se realiza mediante microdifusor
        molecular y electrotérmico AEROBRUMER o cañón termodifusor.</p>
</section>
<section id="desinfeccion">
    <h2>Desinfección, superficial y atmosférica contra bacterias y gérmenes</h2>
    <img src='/assets/images/pages/foto_bacteria.jpg' class="floatright" width='20%'>
    <p>Hay lugares en los que la Desinfección es de vital importancia. En nuestra empresa estamos acostumbrados a tratar gran cantidad de establecimientos: viviendas familiares, oficinas, aseos, vestuarios, gimnasios, polideportivos, bibliotecas, guarderías,
        centros sanitarios, comisarías de policía, calabozos de los juzgados, parques de bomberos, farmacias, etc. y sobre todo industria relacionada con la alimentación.</p>
    <h3>Desinfección superficial:</h3>
    <p>Se realizan mediante pulverización de desinfectantes que no dejen residuos tóxicos ni sean corrosivos. Además deben ser eficaces viricidas, bactericidas y fungicidas.</p>
    <h3>Desinfección atmosférica:</h3>
    <p>Esta se realiza mediante micro-difusor molecular y electrotérmico Aerobrumer y cañón termodifusor muy eficaces para su utilización en explotaciones avícolas y ganaderas, locales industriales, industria alimentaria y colectividades humanas.</p>
</section>    
<section id="desinfeccion_covid">
    <h2>Desinfección, superficial y atmosférica contra CoronaVirus</h2>
    <img src='/assets/images/pages/foto_virus.png' class="floatright" width='15%'>
    <p>Disponemos de productos aprobados por el ministerio, medios, EPI'S y equipos de probada eficiencia contra el coronavirus (entre otros muchos que se han tratado con éxito en nuestra empresa anteriormente). Si fuese necesario o ante cualquier consulta,
        por favor pónganse en contacto con nosotros y les atenderemos rápidamente y con la mayor profesionalidad y calidad posible. <a routerLink="/empresa/coronavirus">Ampliar información</a></p>
</section>