import { Component, OnInit, ViewChild } from '@angular/core';
import { IActionMapping, ITreeOptions, KEYS, TreeComponent, TreeModel, TreeNode, TREE_ACTIONS } from '@circlon/angular-tree-component';

import { ExtranetService } from '../../services/extranet.service';
import { DocumentoDescarga } from '../../models/documento-descarga.model';

@Component({
  selector: 'app-fichas-producto',
  templateUrl: './fichas-producto.component.html',
  styleUrls: ['./fichas-producto.component.css']
})
export class FichasProductoComponent implements OnInit {
  @ViewChild(TreeComponent) private prods: TreeComponent | undefined;  

  nodesProductosHabituales: any[] = [];
  options: ITreeOptions = { 
    actionMapping: {
      mouse: {
        click: (tree: TreeModel, node: TreeNode, $event: any) => {
          if (node.hasChildren) { TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event); }
    
          if (node.data.doc > 0) { 
            // alert(`Descargar documento ${node.data.doc }`); 
            this.showDocument( Number(node.data.doc) );
          }
        }
      },
      keys: {
        [KEYS.ENTER]: (tree: TreeModel, node: TreeNode, $event: any) => {
    
          if (node.hasChildren) { TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event); }
    
          if (node.data.doc > 0) { alert(`Descargar documento ${node.data.doc }`); }
          }
      }
    }
  };
  
  contador = 0;

  constructor( private extranetService: ExtranetService) { }

  ngOnInit(): void {
    this.extranetService.getProductDocuments().subscribe(
      (data) => {
        this.nodesProductosHabituales = this.convertDocsIntoNodes(data);
        this.prods?.treeModel.update();
    });
  }

  showDocument(idDocumento: number) {
    this.extranetService.getDocument(idDocumento)
    .subscribe((doc: DocumentoDescarga) => {
      if(doc.Paginas != null && doc.Paginas?.length > 0) {
        if(doc.Paginas[0].ArchivoDocumento != null) {
          
          const linkSource = 'data:application/pdf;base64,' + doc.Paginas[0].ArchivoDocumento;
          const downloadLink = document.createElement("a");
          const fileName = doc.NombreArchivo ?? 'documento.pdf';
  
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    });
  }

  convertDocsIntoNodes( data: { Id: number, Familia: string, Subfamilia: string, Producto: string, Documento: string}[]): any[]{

    const docs: object[] = [];
    const familias: string[] = this.extraerFamilias(data);

    familias.forEach(familia => {
      const docsFamilia = data.filter(item => item.Familia === familia);
      const docsSubFamilia = this.convertDocsIntoSubfamiliaNodes(docsFamilia);
      this.contador++;
      const prov = `${familia} ${this.contador}`;
      docs.push({id: this.contador, name: familia, children: docsSubFamilia});
    });

    return docs;
  }

  convertDocsIntoSubfamiliaNodes(data: { Id: number, Familia: string, Subfamilia: string, Producto: string, Documento: string}[]): any[]{

    const docs: object[] = [];
    const subfamilias: string[] = this.extraerSubfamilias(data);

    subfamilias.forEach(subfamilia => {
      const docsSubfamilia = data.filter(item => item.Subfamilia === subfamilia);
      const docsProducto = this.convertDocsIntoProductoNodes(docsSubfamilia);
      docs.push({id: this.contador, name: subfamilia, children: docsProducto});
      this.contador++;
    });

    return docs;
  }

  convertDocsIntoProductoNodes(data: { Id: number, Familia: string, Subfamilia: string, Producto: string, Documento: string}[]): any[]{

    const docs: object[] = [];
    const productos: string[] = this.extraerProductos(data);

    productos.forEach(producto => {
      const docsCentro = data.filter(item => item.Producto === producto);
      const docsDocumento = this.convertDocsIntoDocumentoNodes(docsCentro);
      docs.push({id: this.contador, name: producto, children: docsDocumento});
      this.contador++;
    });

    return docs;
  }

  convertDocsIntoDocumentoNodes(data: { Id: number, Familia: string, Subfamilia: string, Producto: string, Documento: string}[]): any[]{

    const docs: object[] = [];

    data.forEach(doc => {
      docs.push({id: this.contador, name: doc.Documento, doc: doc.Id});
      this.contador++;
    });

    return docs;
  }

  extraerFamilias(data: { Id: number, Familia: string, Subfamilia: string, Producto: string, Documento: string}[]): string[] {
    const familias: string[] = [];

    data.forEach(doc => {
      if (!familias.includes(doc.Familia)){
        familias.push(doc.Familia);
      }
    });

    return familias;
  }

  extraerSubfamilias(data: { Id: number, Familia: string, Subfamilia: string, Producto: string, Documento: string}[]): string[] {
    const subfamilias: string[] = [];

    data.forEach(doc => {
      if (!subfamilias.includes(doc.Subfamilia)){
        subfamilias.push(doc.Subfamilia);
      }
    });

    return subfamilias;
  }

  extraerProductos(data: { Id: number, Familia: string, Subfamilia: string, Producto: string, Documento: string}[]): string[] {
    const productos: string[] = [];

    data.forEach(doc => {
      if (!productos.includes(doc.Producto)){
        productos.push(doc.Producto);
      }
    });

    return productos;
  }  

}
