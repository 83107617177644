<div id="cabecera_top">
    <div class="card">
        <div class="card-body">
            <div class="container">
                <div class="col" *ngIf="mobile_device">
                    <app-header-logo></app-header-logo>
                    <div class="row">
                        <div class="col">
                            <app-header-contact-data></app-header-contact-data>
                        </div>
                        <div class="col">
                            <app-header-social></app-header-social>
                        </div>
                        <div class="col">
                            <app-header-isos></app-header-isos>
                        </div>
                    </div>
                    <div class="row">
                        <app-header-extranet-access></app-header-extranet-access>
                    </div>
                    <div class="row">
                        <app-header-callback></app-header-callback>
                    </div>
                </div>

                <div *ngIf="tablet_device">

                    <div class="row" >
                        <div class="col">
                            <app-header-logo></app-header-logo>
                        </div>
                        <div class="col">
                            <app-header-contact-data></app-header-contact-data>
                        </div>
                        <div class="col">
                            <app-header-social></app-header-social>
                        </div>
                        <div class="col">
                            <app-header-isos></app-header-isos>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="col-md py-2">
                                <div class="extranet">
                                    <app-header-callback></app-header-callback>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="col-md py-2">
                                <div class="extranet">
                                    <app-header-extranet-access></app-header-extranet-access>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="!mobile_device&&!tablet_device">
                    <div class="col-2">
                        <app-header-logo></app-header-logo>
                    </div>
                    <div class="col-2">
                        <app-header-contact-data></app-header-contact-data>
                    </div>
                    <div class="col-2">
                        <app-header-social></app-header-social>
                    </div>
                    <div class="col-2">
                        <app-header-isos></app-header-isos>
                    </div>
                    <div class="col-4">
                        <div class="col-md py-2">
                            <div class="extranet">
                                <app-header-extranet-access></app-header-extranet-access>
                                <app-header-callback></app-header-callback>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                </div>
                <app-navbar></app-navbar>
            </div>
        </div>
    </div>
</div>