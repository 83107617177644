import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-control-plagas',
  templateUrl: './control-plagas.component.html',
  styleUrls: [ './control-plagas.component.css' ]
})
export class ControlPlagasComponent {

  constructor() { }

}