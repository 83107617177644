import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

// Modules
import { PagesRoutingModule } from './pages/pages.routing';
import { ExtranetRoutingModule } from './extranet/extranet.routing';
import { AuthRoutingModule } from './auth/auth.routing';

import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', redirectTo: '/empresa', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent}
];

const routerOptions: ExtraOptions = {
  // useHash: true,
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled'
};

@NgModule({
  imports: [
    RouterModule.forRoot(routes, routerOptions),
    PagesRoutingModule,
    ExtranetRoutingModule,
    AuthRoutingModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
