import { Component, OnDestroy } from '@angular/core';
import { Router, ActivationEnd, ActivatedRoute, Data } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styles: [
  ]
})
export class BreadcrumbsComponent implements OnDestroy {

  public titulo: string = '';
  public tituloSubs$: Subscription;

  
  constructor( private router: Router, private route: ActivatedRoute ) {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.tituloSubs$ = 
    this.getArgumentosRuta()
                        .subscribe( ({ titulo }) => {
                            this.titulo = titulo;
                            document.title = `Gar-San Sanidad Ambiental - ${ titulo }`;
                        });
  }
  ngOnDestroy(): void {
    this.tituloSubs$.unsubscribe();
  }


  getArgumentosRuta(): Observable<Data> {

    return this.router.events.pipe(
          filter( (event): event is ActivationEnd => event instanceof ActivationEnd ),
          filter( (event: ActivationEnd) => event.snapshot.firstChild === null  ),
          map( (event: ActivationEnd) => event.snapshot.data ),
      );
  }


}
