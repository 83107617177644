import { Component, HostListener, OnInit } from '@angular/core';
import { UsuarioService } from '../../services/usuario.service';
import { Usuario } from '../../models/usuario.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public usuario: Usuario;
  public userName: string;
  public mobile_device: boolean = false;
  public tablet_device: boolean = false;
  public getScreenWidth: any;
  public getScreenHeight: any;

  constructor( 
      private usuarioService: UsuarioService,
      private router: Router
    ) {
      this.usuario = usuarioService.usuario;
      this.userName = usuarioService.usuario.nombre == '' ? 'Acceso Clientes: Entrar' : 'Acceso Clientes: ' + usuarioService.usuario.nombre;
  }
  ngOnInit(): void {
    this.getScreenHeight = window.innerHeight;
    this.getScreenWidth = window.innerWidth;
    switch(true){
      case (this.getScreenWidth<=600):
        this.mobile_device=true;
        this.tablet_device=false;
        break;
      case (this.getScreenWidth>600 && this.getScreenWidth<1024):
        this.mobile_device=false;    
      this.tablet_device=true;
          break;
      default:
          this.tablet_device=false;
          this.mobile_device=false;
          break;        
    }
    // console.log("mobile", this.mobile_device, "tablet", this.tablet_device);
  }

  logout() {
    // this.usuarioService.logout();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    switch(true){
      case (this.getScreenWidth<=600):
        this.mobile_device=true;
        this.tablet_device=false;
        break;
      case (this.getScreenWidth>600 && this.getScreenWidth<1024):
        this.mobile_device=false;    
      this.tablet_device=true;
          break;
      default:
          this.tablet_device=false;
          this.mobile_device=false;
          break;        
    }
    // console.log("mobile", this.mobile_device, "tablet", this.tablet_device);
  }
}
