<h1>Empresa</h1>
<div id="carouselNoticiasPortada" class="carousel slide carousel-fade" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselNoticiasPortada" data-bs-slide-to="0" aria-label="Slide 1" class="active" aria-current="true" ></button>
      <button type="button" data-bs-target="#carouselNoticiasPortada" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselNoticiasPortada" data-bs-slide-to="2" aria-label="Slide 3"></button>
      <button type="button" data-bs-target="#carouselNoticiasPortada" data-bs-slide-to="3" aria-label="Slide 4"></button>
      <button type="button" data-bs-target="#carouselNoticiasPortada" data-bs-slide-to="4" aria-label="Slide 5"></button>
      <button type="button" data-bs-target="#carouselNoticiasPortada" data-bs-slide-to="5" aria-label="Slide 6"></button>
      <button type="button" data-bs-target="#carouselNoticiasPortada" data-bs-slide-to="6" aria-label="Slide 7"></button>
    </div>
    <div class="carousel-inner">
        <div class="carousel-item active">
            <img src="/assets/images/carrousel/portada_aves_urbanas.jpg" class="d-block w-100" alt="Tratamientos preventivos control aves urbanas">
            <div class="carousel-caption d-none d-md-block">
                <h5>Aves Urbanas</h5>
                <h6>Tratamientos preventivos control aves urbanas</h6>
            </div>
        </div>
        <div class="carousel-item">
            <img src="/assets/images/carrousel/portada_carcoma.jpg" class="d-block w-100" alt="...">
            <div class="carousel-caption d-none d-md-block">
                <h5>Xilófagos</h5>
                <h6>Tratamientos curativos para madera en vigas, muebles, obras de arte</h6>
            </div>
        </div>
        <div class="carousel-item">
            <img src="/assets/images/carrousel/portada_agua.jpg" class="d-block w-100" alt="...">
            <div class="carousel-caption d-none d-md-block">
                <h5>Legionella</h5>
                <h6>Tratamientos preventivos de Legionelosis mediante limpieza y desinfección</h6>
            </div>
        </div>
        <div class="carousel-item">
            <img src="/assets/images/carrousel/portada_industria_alimentaria.jpg" class="d-block w-100" alt="...">
            <div class="carousel-caption d-none d-md-block">
                <h5>Industria Alimentaria</h5>
                <h6>Control de plagas respetuoso con los estándares internacionales de calidad</h6>
            </div>
        </div>
      <div class="carousel-item">
          <img src="/assets/images/carrousel/portada_mosquitos.jpg" class="d-block w-100" alt="...">
          <div class="carousel-caption d-none d-md-block">
              <h5>Mosquitos</h5>
              <h6>Control de mosquitos mediante sistema atrapa insectos</h6>
            </div>
      </div>
      <div class="carousel-item">
        <img src="/assets/images/carrousel/portada_mundo.jpg" class="d-block w-100" alt="...">
        <div class="carousel-caption d-none d-md-block">
          <h5>ISO 14001</h5>
          <h6>35 años respetando el medioambiente</h6>
        </div>
    </div>      
    <!-- <div class="carousel-item">
      <img src="/assets/images/carrousel/portada_coronavirus.jpg" class="d-block w-100" alt="Tratamientos de desinfección por positivo en SARS-CoV-2.">
      <div class="carousel-caption d-none d-md-block">
        <h5 class="text-black text-opacity-75">Covid19</h5>
        <h6 class="text-black text-opacity-75">Tratamientos de desinfección por positivo en SARS-CoV-2.</h6>
      </div>
    </div> -->
</div>
<button class="carousel-control-prev" type="button" data-bs-target="#carouselNoticiasPortada" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
</button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselNoticiasPortada" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
    </button>
  </div>
<section id="quienes_somos">
    <h2>Quiénes somos</h2>
    <ul>
        <li>Desde 1.986 y con más de 37 años de experiencia, GAR-SAN se ha posicionado como una empresa líder y de referencia en el sector de Control de Plagas y Tratamientos contra Legionella.</li>
        <li>GAR-SAN ofrece soluciones integrales a las necesidades de sus clientes, con una filosofía de Servicio, ahorro económico y respeto al medioambiente.</li>
        <li>Con un marcado carácter de formación, GAR-SAN imparte cursos en colaboración con la Dirección General de Salud Pública, entre otros, en los que nuestros clientes pueden estar al día en cuanto a legislación y problemáticas actuales.</li>
        <li>Empresa certificada en el Sistema de Calidad ISO 9001 y de Gestión Ambiental ISO 14001, certificado por S.G.S.</li>
        <div class="row">
            <div class="col col-xs-6 col-lg-2">
                <a href="http://www.sgs.com/certifiedclients" target="_blank"><img class="img-fluid" src="assets/images/pages/iso9001.jpg"></a>
            </div>
            <div class="col col-xs-6 col-lg-2">
                <a href="http://www.sgs.com/certifiedclients" target="_blank"><img class="img-fluid" src="assets/images/pages/iso14001.jpg"></a>
            </div>
        </div>
        <li>GAR-SAN ha implantado y mantiene: RGPD, COMPLIANCE PENAL y CIBERSEGURIDAD.</li>
        <li>Para poder ofrecer una mejor garantía tiene contratada un seguro de responsabilidad civil de 850.000 €.</li>
        <li>GAR-SAN trabaja siguiendo la norma UNE-EN 16636:2015, en la que se definen buenas prácticas en los planes de Desinfección, Desinsectación y Desratización. También adaptamos los servicios a normas internacionales (ISO 22.000, IFS, BRC, …)</li>
        <li>GAR-SAN pertenece a CEPA. Además, ha colaborado con ANECPLA en la modificación del R.D. 865/2003 de instalaciones con probabilidad de proliferación y dispersión de Legionella.</li>
        <div class="row">
            <div class="col col-xs-6 col-lg-2">
                <img class="img-fluid" src="assets/images/pages/logo_anecpla.jpg">
            </div>
            <div class="col col-xs-6 col-lg-2">
                <img class="img-fluid" src="assets/images/pages/logo_cepa.jpg">
            </div>
        </div>
        <li>GAR-SAN pertenece a CEPA. Además, ha colaborado con ANECPLA en la modificación del R.D. 865/2003 de instalaciones con probabilidad de proliferación y dispersión de Legionella.</li>
        <li>Empresa formadora a nivel nacional, así como a nivel regional con la Consellería de Salud Pública, para la obtención del carnet para tratar instalaciones de proliferación y dispersión de Legionella.</li>
    </ul>
</section>
<section id="cobertura_nacional">
    <h2>Cobertura Nacional</h2>
</section>
<ul>
    <li>Con central en la Provincia de Alicante y nuestra amplia red de colaboradores damos cobertura a todo el territorio nacional.</li>
    <img class="img-fluid center mt-3" src="assets/images/pages/mapa_cobertura_es.jpg">
    <li>Inscrita en el Registro Oficial de Establecimientos y Servicios Biocidas de la Comunidad Valenciana, otorgado en base a la Resolución del Director de Salud Pública de fecha 19/07/2006, con el número 0011-CV // 0094-CV. Este registro nos autoriza a realizar tratamientos en todo el territorio nacional y en la Unión Europea.</li>
</ul>
<section id="medios_fisicos">
    <h2>Medios técnicos</h2>
</section>
<ul>
    <li>Flota de vehículos equipados con la tecnología necesaria para el trasporte de productos químicos para el servicio, dotados de mampara de protección que aísla a conductor y acompañante de los productos situados en la parte posterior del automóvil.</li>
    <img src="assets/images/pages/vehiculos_empresa.jpg" class="img-fluid rounded center mt-3 mb-3">
    <li>En GAR-SAN siempre contamos con la última tecnología existente en el mercado para la realización de sus servicios: Emisor de Microondas para el tratamiento de Xilófagos en madera, Termonebulizadores y Nebulizadoras de U.L.V. (especiales para desinfecciones COVID19), Cámaras de CCTV para inspección en lugares inaccesibles, …</li>
    <li>Conexión on-line permanente entre técnicos y oficina para consulta y traspaso de información, pudiendo conocer en tiempo real la evolución de los trabajos en su empresa.</li>
    <li>Vehículos dotados de sistemas de geo-posicionamiento para mayor optimización de los recursos móviles.</li>
    <li>Contamos con un Área de Clientes, donde se puede consultar, a través de internet las 24h del día, los certificados de los servicios realizados, plano de instalación, informes, fichas de productos, carnés y toda la información relativa al servicio prestado.</li>
    <li>Equipo comercial con vehículos exclusivamente destinados a funciones comerciales y atención al cliente.</li>
    <li>Sede ubicada en Sant Joan d’Alacant, con más de 300 m2 de instalaciones distribuidos en oficinas, almacén registrado, aulas de formación, etc.</li>
</ul>
<section id="medios_humanos">
    <h2>Medios humanos</h2>
</section>
El Real Decreto 830/2010 artículo 5 obliga a las empresas de servicios de control de plagas a tener un licenciado o un técnico superior o una cualificación Profesional de Nivel III, como director técnico, el cual es el encargado de supervisar y responsabilizarse de los servicios realizados.
<ul>
    <li>Un Ingeniero Químico, con amplios conocimientos en tratamientos de agua, calidad y medio smbiente. Supervisa el cumplimiento y la idoneidad de los trabajos, disponible para solventar cualquier problema.</li>
    <li>Dos peritos Judiciales, con una amplia experiencia.</li>
    <li>Una experta contable diplomada en Empresariales.</li>
    <!-- <li>Una licenciada en Administración y Dirección de Empresas.</li> -->
    <li>Un analista de sistemas.</li>
    <li>Un coordinador de servicios y logística.</li>
    <li>Personal de oficina encargado de la administración y gestión.</li>
    <li>Asesoría fiscal/contables.</li>
    <li>18 aplicadores con alta cualificación y experiencia media de 10 años. Poseedores del Certificado de Profesionalidad de Servicios para el Control de Plagas N2, y 5 de ellos Certificado de Profesionalidad de Gestión de Servicios para el Control de Organismos Nocivos N3, así como el “Carnet de Manipulador de Plaguicidas de uso Fitosanitario nivel Cualificado”. Este personal actuará bajo la dirección del técnico competente de la empresa adjudicataria y bajo las instrucciones de la Unidad encargada del control y supervisión de los servicios.</li>
    <li>Equipo complementario: En caso de necesidad disponemos de un equipo de Técnicos superiores en Salud Ambiental (N2 en Sanidad Ambiental).</li>
</ul>