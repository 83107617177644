import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../../services/usuario.service';

@Component({
  selector: 'app-navbar-extranet',
  templateUrl: './navbar-extranet.component.html',
  styleUrls: ['./navbar-extranet.component.css']
})
export class NavbarExtranetComponent implements OnInit {

  constructor(private usuarioService: UsuarioService) { }

  ngOnInit(): void {
  }

  cerrarSesion(): void {
    this.usuarioService.logout();
  }

}
