import { Component, OnInit, ViewChild } from '@angular/core';
import { IActionMapping, ITreeOptions, KEYS, TreeComponent, TreeModel, TreeNode, TREE_ACTIONS } from '@circlon/angular-tree-component';

import { ExtranetService } from '../../services/extranet.service';
import { DocumentoDescarga } from '../../models/documento-descarga.model';

@Component({
  selector: 'app-documentos-garsan',
  templateUrl: './documentos-garsan.component.html',
  styleUrls: ['./documentos-garsan.component.css']
})
export class DocumentosGarsanComponent implements OnInit {
  @ViewChild(TreeComponent) private docsgarsan: TreeComponent | undefined;  

  nodesDocGenGarsan: any[] = [];
  options: ITreeOptions = { 
    actionMapping: {
      mouse: {
        click: (tree: TreeModel, node: TreeNode, $event: any) => {
          if (node.hasChildren) { TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event); }
    
          if (node.data.doc > 0) { 
            // alert(`Descargar documento ${node.data.doc }`); 
            this.showDocument( Number(node.data.doc) );
          }
        }
      },
      keys: {
        [KEYS.ENTER]: (tree: TreeModel, node: TreeNode, $event: any) => {
    
          if (node.hasChildren) { TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event); }
    
          if (node.data.doc > 0) { alert(`Descargar documento ${node.data.doc }`); }
          }
      }
    }
  };
  
  contador = 0;

  constructor( private extranetService: ExtranetService) { }

  ngOnInit(): void {
    this.extranetService.getGeneralDocuments().subscribe(
      (data) => {
        this.nodesDocGenGarsan = this.convertDocsIntoNodes(data);
        this.docsgarsan?.treeModel.update();
    });
  }

  showDocument(idDocumento: number) {
    this.extranetService.getDocument(idDocumento)
    .subscribe((doc: DocumentoDescarga) => {
      if(doc.Paginas != null && doc.Paginas?.length > 0) {
        if(doc.Paginas[0].ArchivoDocumento != null) {
          
          const linkSource = 'data:application/pdf;base64,' + doc.Paginas[0].ArchivoDocumento;
          const downloadLink = document.createElement("a");
          const fileName = doc.NombreArchivo ?? 'documento.pdf';
  
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    });
  }

  convertDocsIntoNodes( data: { Id: number, Grupo: string, Documento: string}[]): any[]{

    const docs: object[] = [];
    const grupos: string[] = this.extraerGrupos(data);

    grupos.forEach(grupo => {
      const docsGrupo = data.filter(item => item.Grupo === grupo);
      const docsDocumento = this.convertDocsIntoDocumentoNodes(docsGrupo);
      docs.push({id: this.contador, name: grupo, children: docsDocumento});
      this.contador++;
    });

    return docs;
  }

  convertDocsIntoDocumentoNodes(data: { Id: number, Grupo: string, Documento: string}[]): any[]{

    const docs: object[] = [];

    data.forEach(doc => {
      docs.push({id: this.contador, name: doc.Documento, doc: doc.Id});
      this.contador++;
    });

    return docs;
  }

  extraerGrupos(data: { Id: number, Grupo: string, Documento: string}[]): string[] {
    const grupos: string[] = [];

    data.forEach(grupo => {
      if (!grupos.includes(grupo.Grupo)){
        grupos.push(grupo.Grupo);
      }
    });

    return grupos;
  }  

}
