import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tratamientos-madera',
  templateUrl: './tratamientos-madera.component.html',
  styles: [
  ]
})
export class TratamientosMaderaComponent {

  constructor() { }


}