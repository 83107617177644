<div id="main-wrapper">

    <app-header></app-header>

    <h1>Extranet Gar-San</h1>
    <app-navbar-extranet></app-navbar-extranet>

    <div class="container" id="seccion">
        <!-- <h4>Estamos renovando nuestra extranet, en breve podrá acceder a ella</h4>
        <h4>Fecha estimada restauración del servicio: 12/04/2022</h4>
        <h4>Les rogamos disculpen las molestias</h4>
        <h4>Si necesitan cualquier información adicional no duden en solicitarlo a nuestros teléfonos o email</h4> -->

        <router-outlet></router-outlet>

    </div>

</div>

<!-- <app-modal-imagen></app-modal-imagen> -->