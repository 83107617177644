<h1>Extranet</h1>
<p>En nuestra extranet se puede consultar los certificados de servicios realizados en su empresa, así como otros documentos como los planos de la instalación y las fichas de productos, además de los certificados de empresa.</p>
<h2>Login</h2>
<div class="row mt-5 mb-5">
    <div class="col-1 col-sm-3"></div>
    <div class="col-10 col-sm-6">
        <div class="card">
            <div class="card-body">
                <form class="form-horizontal form-material" autocomplete="off" [formGroup]="miForm" (ngSubmit)="login()">
                    <p>e-mail de usuario:</p>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="eMail" formControlName="email">
                    </div>
                    <p>Contraseña:</p>
                    <div class="form-group">
                        <input class="form-control" type="password" placeholder="Ingrese su contraseña" formControlName="password">
                    </div>
                
                    <div class="form-group text-center mt-5 mb-5">
                        <div class="col-xs-12">
                            <button class="btn btn-info btn-lg btn-block text-uppercase btn-rounded" type="submit" [disabled]="miForm.invalid">Login</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-1 col-sm-3"></div>
</div>
<div class="mb-5">
    <p class="floatCenter">Si no dispone de los datos de acceso le ruego se ponga en contacto con nuestro personal, para solicitarlo</p>
    <br>
</div>

