import { Component, OnInit } from '@angular/core';

import { SettingsService } from '../services/settings.service';
import { SidebarService } from '../services/sidebar.service';

@Component({
  selector: 'app-extranet',
  templateUrl: './extranet.component.html',
  styles: [
  ]
})
export class ExtranetComponent implements OnInit {

  constructor( ) { }

  ngOnInit(): void {

  }

}
