import { Component, HostListener, OnInit } from '@angular/core';
import { UsuarioService } from '../../services/usuario.service';
import { Usuario } from '../../models/usuario.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-extranet-access',
  templateUrl: './header_extranet_access.component.html',
  styleUrls: ['./header_extranet_access.component.css']
})
export class HeaderExtranetAccessComponent {

  public usuario: Usuario;
  public userName: string;

  constructor( 
      private usuarioService: UsuarioService,
      private router: Router
    ) {
      this.usuario = usuarioService.usuario;
      this.userName = usuarioService.usuario.nombre == '' ? 'Acceso Clientes: Entrar' : 'Acceso Clientes: ' + usuarioService.usuario.nombre;
  }
  logout() {
    // this.usuarioService.logout();
  }

}
