<nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <!-- <a class="navbar-brand" href="#">Navbar</a> -->
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="submenuEmpresa" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span class="navbar-text">Empresa</span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="submenuEmpresa">
              <li><a class="dropdown-item" routerLink="/empresa" fragment="quienes_somos">¿Quiénes somos?</a></li>
              <li><a class="dropdown-item" routerLink="/empresa" fragment="cobertura_nacional">Cobertura Nacional</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" routerLink="/empresa" fragment="medios_fisicos">Medios Físicos</a></li>
              <li><a class="dropdown-item" routerLink="/empresa" fragment="medios_humanos">Medios humanos</a></li>
              <div class="dropdown-divider"></div>
              <li><a class="dropdown-item" routerLink="/empresa/calidad_medioambiente">Calidad y Medioambiente</a></li>
              <!-- <li><a class="dropdown-item" routerLink="/empresa/trabajos_altura">Trabajos en Altura</a></li> -->
            </ul>
          </li>
          <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="submenuDDD" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="navbar-text">Control plagas</span>
              </a>
              <ul class="dropdown-menu" aria-labelledby="submenuDDD">
              <li><a class="dropdown-item" routerLink="/empresa/control-plagas" fragment="ratas_ratones">Ratas y Ratones</a></li>
              <li><a class="dropdown-item" routerLink="/empresa/control-plagas" fragment="desinsectacion">Cucarachas, Hormigas, Pulgas y Chinches</a></li>
              <li><a class="dropdown-item" routerLink="/empresa/control-moscas-mosquitos">Moscas y mosquitos</a></li>
              <div class="dropdown-divider"></div>
              <li><a class="dropdown-item" routerLink="/empresa/procesionaria">Procesionaria</a></li>
              <div class="dropdown-divider"></div>
              <li><a class="dropdown-item" routerLink="/empresa/control-plagas" fragment="desinfeccion">Bacterias, Gérmenes y Virus</a></li>
              <li><a class="dropdown-item" routerLink="/empresa/control-plagas" fragment="desinfeccion_covid">Desinfecciones COVID Virus SARS-CoV-2</a></li>
              <div class="dropdown-divider"></div>
              <li><a class="dropdown-item" routerLink="/empresa/aves_urbanas">Aves urbanas</a></li>
              </ul>
          </li>
          <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="submenuMadera" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="navbar-text">Trat. madera</span>
              </a>
              <ul class="dropdown-menu" aria-labelledby="submenuMadera">
                  <li><a class="dropdown-item" routerLink="/empresa/tratamientos-madera">Termitas, Carcoma, Hongos</a></li>
                  <!-- <li><a class="dropdown-item" routerLink="/empresa/tratamientos-madera">Carcoma</a></li>
                  <li><a class="dropdown-item" routerLink="/empresa/tratamientos-madera">Hongos</a></li> -->
              </ul>
          </li>

          <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="submenuAgua" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="navbar-text">Trat. agua</span>
              </a>
              <ul class="dropdown-menu" aria-labelledby="submenuAgua">
                  <li><a class="dropdown-item" routerLink="/empresa/tratamientos-agua" fragment="legionela">Legionella</a></li>
                  <li><a class="dropdown-item" routerLink="/empresa/tratamientos-agua" fragment="analiticas">Analíticas</a></li>
                  <li><a class="dropdown-item" routerLink="/empresa/tratamientos-agua" fragment="dosificadores">Paneles dosificadores</a></li>
                  <li><a class="dropdown-item" routerLink="/empresa/tratamientos-agua" fragment="limpieza_depositos">Limpieza Depósitos</a></li>
                  <li><a class="dropdown-item" routerLink="/empresa/tratamientos-agua" fragment="piscinas">Piscinas</a></li>
              </ul>
          </li>

          <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="submenuAlimentaria" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="navbar-text">Ind. Alimentaria</span>
              </a>
              <ul class="dropdown-menu" aria-labelledby="submenuAlimentaria">
                  <li><a class="dropdown-item" routerLink="/empresa/industria-alimentaria" fragment="industria_alimentaria" (click)="toView('industria_alimentaria')">Industria Alimentaria</a></li>
                  <li><a class="dropdown-item" routerLink="/empresa/industria-alimentaria" fragment="carnicerias" (click)="toView('carnicerias')">Carnicerías</a></li>
              </ul>
          </li>

          <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="submenuFormacion" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="navbar-text">Formación</span>
              </a>
              <ul class="dropdown-menu" aria-labelledby="submenuFormacion">
                  <li><a class="dropdown-item" routerLink="/empresa/formacion" fragment="empresas" (click)="toView('empresas')">Plagas en Empresas Ind. Alimentaria</a></li>
                  <li><a class="dropdown-item" routerLink="/empresa/formacion" fragment="institutos" (click)="toView('institutos')">Charlas en Institutos</a></li>
                  <li><a class="dropdown-item" routerLink="/empresa/formacion" fragment="sanidad" (click)="toView('sanidad')">Sanidad</a></li>
              </ul>
          </li>

          <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="submenuContacto" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="navbar-text">Contacto</span>
              </a>
              <ul class="dropdown-menu" aria-labelledby="submenuContacto">
                  <li><a class="dropdown-item" routerLink="/empresa/contacto" (click)="toView('llamada')">Le llamamos gratis</a></li>
                  <li><a class="dropdown-item" routerLink="/empresa/contacto" (click)="toView('contacto')">Contacto</a></li>
                  <div class="dropdown-divider"></div>
                  <li><a class="dropdown-item" routerLink="/extranet">Acceso Clientes</a></li>
              </ul>
          </li>

        </ul>

        <!-- <form class="d-flex">
          <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
          <button class="btn btn-outline-success" type="submit">Search</button>
        </form> -->
      </div>
    </div>
</nav>
