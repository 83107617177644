import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ContactoService } from '../../services/contacto.service';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent {

  public formSubmitted = false;
  public llamadaForm = this.fb.group({
    nombre: [],
    telefono: [],
    acepto_condiciones: [false]
  });
  constructor(  private router: Router,
                private fb: FormBuilder,
                private contactoService: ContactoService) { }

  llamada(data: any): void {

    if (data.nombre !== null && data.nombre !== '' && data.telefono !== null && data.telefono !== '' && data.acepto_condiciones)
    {
      this.contactoService.solicitarLlamada( this.llamadaForm.value )
        .subscribe( resp => {

          Swal.fire('Hemos registrado correctamente su petición, en breve le llamaremos dentro del horario de atención' );

          // Navegar al Dashboard
          this.router.navigateByUrl('/');

        }, (err) => {
          // Si sucede un error
          Swal.fire('Error', err.error.msg, 'error' );
        });
    } else {
      Swal.fire('Error', 'Tiene que rellenar todos los datos y aceptar los términos', 'error');
    }

  }

}
