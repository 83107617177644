<div id="main-wrapper">

    <app-header></app-header>

    <div class="container pt-1 pb-1 pl-1 pr-1" id="seccion">
        <!-- Breadcrumbs -->
        <!-- <app-breadcrumbs></app-breadcrumbs> -->

        <!-- Mi sistema de rutas -->
        <router-outlet></router-outlet>

    </div>

    <app-footer></app-footer>

</div>

<!-- <app-modal-imagen></app-modal-imagen> -->