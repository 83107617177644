import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { UsuarioService } from './usuario.service';
import { DocumentoDescarga } from '../models/documento-descarga.model';

const base_url = environment.base_url;

declare const gapi: any;

@Injectable({
  providedIn: 'root'
})
export class ExtranetService {

  constructor(
    private usuarioService: UsuarioService,
    private http: HttpClient,
    private ngZone: NgZone ) {

    // this.googleInit();
  }

  public getDocument(idDocumento: number): Observable<DocumentoDescarga> {
    const httpHeaders = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Authorization', this.usuarioService.token);

    const requestBody = {
      Filtros: '',
      Objeto: idDocumento,
      Operacion: 'Servicios.Extranet.DocumentoDescarga.DescargarDocumento',
      Token: ''
    };

    return this.http.post(`${ base_url }/api/extranet`, requestBody, {headers: httpHeaders} )
    .pipe(
      tap( (resp: any) => {
        // console.log(resp);
      }),
      map( (resp: any) => {
          if (resp.Resultado === 'OK') {
              return resp.Objeto;
            } else {
              return null;
            }
          }
      )
    );

  }

  public getClientDocuments( ): Observable<any> {

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', this.usuarioService.token);

    if (this.usuarioService.usuario.id === undefined) {
      return of([]);
    }
    const requestBody = {
      Filtros: '',
      Objeto: {
        idCliente: this.usuarioService.usuario.idCliente ?? 0,
        idClienteFacturacion: this.usuarioService.usuario.idClienteFacturacion ?? 0,
        zonaCliente: this.usuarioService.usuario.zonaCliente ?? '',
        idClienteServicio: this.usuarioService.usuario.idClienteServicio ?? 0,
        idIntermediario: this.usuarioService.usuario.idIntermediario ?? 0 },
      Operacion: 'Servicios.Extranet.Documento.ObtenerListaDocumentos',
      Token: ''
    };

    return this.http.post(`${ base_url }/api/extranet`, requestBody, {headers: httpHeaders} )
      .pipe(
        tap( (resp: any) => {
          // console.log(resp);
        }),
        map( (resp: any) => {
            if (resp.Resultado === 'OK') {
                return resp.Objeto;
              } else {
                return null;
              }
            }
        )
      );

  }

  public getProductDocuments( ): Observable<any> {

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', this.usuarioService.token);

    if (this.usuarioService.usuario.id === undefined) {
      return of([]);
    }
    const requestBody = {
      Filtros: '',
      Objeto: {
        idCliente: this.usuarioService.usuario.idCliente ?? 0,
        idClienteFacturacion: this.usuarioService.usuario.idClienteFacturacion ?? 0,
        zonaCliente: this.usuarioService.usuario.zonaCliente ?? '',
        idClienteServicio: this.usuarioService.usuario.idClienteServicio ?? 0,
        idIntermediario: this.usuarioService.usuario.idIntermediario ?? 0 },
      Operacion: 'Servicios.Extranet.Documento.ObtenerListaDocumentosProducto',
      Token: ''
    };

    return this.http.post(`${ base_url }/api/extranet`, requestBody, {headers: httpHeaders} )
      .pipe(
        tap( (resp: any) => {
          // console.log(resp);
        }),
        map( (resp: any) => {
            if (resp.Resultado === 'OK') {
                return resp.Objeto;
              } else {
                return null;
              }
            }
        )
      );
  }

  public getGeneralDocuments( ): Observable<any> {

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', this.usuarioService.token);

    if (this.usuarioService.usuario.id === undefined) {
      return of([]);
    }
    const requestBody = {
      Filtros: '',
      Objeto: {
        idCliente: this.usuarioService.usuario.idCliente ?? 0,
        idClienteFacturacion: this.usuarioService.usuario.idClienteFacturacion ?? 0,
        zonaCliente: this.usuarioService.usuario.zonaCliente ?? '',
        idClienteServicio: this.usuarioService.usuario.idClienteServicio ?? 0,
        idIntermediario: this.usuarioService.usuario.idIntermediario ?? 0 },
      Operacion: 'Servicios.Extranet.Documento.ObtenerListaDocumentosGenerales',
      Token: ''
    };

    return this.http.post(`${ base_url }/api/extranet`, requestBody, {headers: httpHeaders} )
      .pipe(
        tap( (resp: any) => {
          // console.log(resp);
        }),
        map( (resp: any) => {
            if (resp.Resultado === 'OK') {
                return resp.Objeto;
              } else {
                return null;
              }
            }
        )
      );

  }

}
