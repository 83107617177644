import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calidad-medioambiente',
  templateUrl: './calidad-medioambiente.component.html',
  styleUrls: ['./calidad-medioambiente.component.css']
})
export class CalidadMedioambienteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
