import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-industria-alimentaria',
  templateUrl: './industria-alimentaria.component.html',
  styleUrls: ['./industria-alimentaria.component.css']
})
export class IndustriaAlimentariaComponent {

  constructor() { }

}
