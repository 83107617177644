<h1>Control mosquitos</h1>
<h2>Mosquitos</h2>
<p>Los mosquitos en general son una familia de insectos que pueden transmitir una gran variedad de enfermedades. El ciclo de desarrollo dura 2 semanas. Los huevos eclosionan en presencia de agua. En su estado larval, el mosquito vive en el agua y se alimenta
    de material orgánico y plantas, posteriormente se transforma en pupa y se convierte en adulto en uno o dos días.</p>
<ul>
    <li>ENE, FEB, MAR: Adulto hibernando. Durante estos meses, se pueden realizar tratamientos adulticidas mediante lámparas CO2 y/o Feromonas y Luz UV (todos) y termonebulizaciones en arbolado y vegetación exterior (solo mosquito común).</li>
    <li>ABR, MAY, JUN, JUL, AGO, SEP, OCT Adultos salen de la hibernación y se aparean. A finales de mes se produce la puesta de huevos en zonas húmedas. Albercas, acequias, pozas e imbornales en el caso del mosquito común y en cualquier pequeño resto de
        agua como platos de maceta en el caso del mosquito tigre. Para combatir las larvas se puede pulverizar Bacillus Thuringiensis var. Israeliensis para entornos acuáticos donde la carga de materia en suspensión no supere ciertos límites y para aguas
        eutrofizadas (gran carga de nutrientes) se puede usar Diflubenzurón, inihibidor síntesis de la quitina en pastillas. En el caso del mosquito tigre es imprescindible la eliminación de nichos con agua estancada, como platos de macetas, cubos, etc…
        Tratamientos adulticidas mediante lámparas CO2 y/o Feromonas y Luz UV.</li>
    <li>NOV Adulto hibernando Durante estos meses, se pueden realizar tratamientos adulticidas mediante lámparas CO2 y/o Feromonas y Luz UV (todos) y termonebulizaciones en arbolado y vegetación exterior (solo mosquito común).</li>
</ul>
<h2>Dispositivos de captura Youget Out</h2>
<img src="/assets/images/pages/youget_out.jpg" class="floatleft">
<p>YOU GET OUT es un dispositivo para la captura y el seguimiento de los mosquitos y otros insectos chupadores de sangre. Emite en 360 grados los principales estímulos que siguen las hembras de mosquito cuando van en busca de su presa: el calor de los mamíferos,
    estímulos olfativos asociados a emanaciones dérmicos (usando ACTIVIX PRO), colores oscuros, emisiones de luz UVA y producción de dióxido de carbono por fotocatálisis. Los mosquitos siguen estos estímulos hasta la trampa que los origina y al acercarse
    son absorbidos por la succión que genera el ventilador del dispositivo. Trabajando de manera constante, contribuye a la reducción de la población de mosquitos presentes además de otros insectos molestos, tales como moscas, en un área de aproximadamente
    500 m2. Este sistema de captura está libre de sustancias tóxicas y por lo tanto es respetuoso con el medio ambiente y las personas</p>
<p>Métodos y tipos de aplicación: Tiene que posicionarse en el exterior a una distancia de alrededor de 5-7m de las zonas frecuentada por las personas. YOU GET OUT incluye un ACTIVIX PRO, producto sinérgico capaz de aumentar la atracción del equipo. Resistente
    a la intemperie. Asegúrese de leer el manual de instrucciones antes de su uso.</p>
<p>Se recomienda iniciar su tratamiento en Abril y utilizarlo hasta octubre.</p>

<h2>Dispositivos de captura Agritrap</h2>
<img src="/assets/images/pages/jupiter.jpg" class="floatleft">
<p>Los aparatos Agritrap son unos dispositivos para la captura y el seguimiento de los mosquitos y otros insectos chupadores de sangre. Emite en 360 grados los principales estímulos que siguen las hembras de mosquito cuando van en busca de su presa: el calor
    de los mamíferos, colores oscuros, emisiones de luz UVA y producción de dióxido de carbono por fotocatálisis. Los mosquitos siguen estos estímulos hasta la trampa que los origina y al acercarse son absorbidos por la succión que genera el ventilador
    del dispositivo. Trabajando de manera constante, contribuye a la reducción de la población de mosquitos presentes además de otros insectos molestos, tales como moscas o polillas, en un área de aproximadamente 500 m2. </p>
<p>Los modelos de exterior disponen de un sistema de compuertas que evitan que los mosquitos se salgan al apagarlos</p>
<p>Métodos y tipos de aplicación: Tiene que posicionarse en el exterior a una distancia de alrededor de 5-7m de las zonas frecuentada por las personas. Resistente a la intemperie según modelos. Asegúrese de leer el manual de instrucciones antes de su uso.</p>
<p>Se recomienda iniciar su tratamiento en Abril y utilizarlo hasta octubre.</p>