import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { HeaderLogoComponent } from './header_logo/header_logo.component';
import { HeaderContactDataComponent } from './header_contact_data/header_contact_data.component';
import { HeaderSocialComponent } from './header_social/header_social.component';
import { HeaderIsosComponent } from './header_isos/header_isos.component';
import { HeaderExtranetAccessComponent } from './header_extranet_access/header_extranet_access.component';
import { HeaderCallbackComponent } from './header_callback/header_callback.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NavbarExtranetComponent } from './navbar-extranet/navbar-extranet.component';



@NgModule({
  declarations: [
    BreadcrumbsComponent,
    SidebarComponent,
    HeaderComponent,
    HeaderLogoComponent,
    HeaderContactDataComponent,
    HeaderSocialComponent,
    HeaderIsosComponent,
    HeaderExtranetAccessComponent,
    HeaderCallbackComponent,
    FooterComponent,
    NavbarComponent,
    NavbarExtranetComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule
  ],
  exports: [
    BreadcrumbsComponent,
    SidebarComponent,
    HeaderComponent,
    HeaderLogoComponent,
    HeaderContactDataComponent,
    HeaderSocialComponent,
    HeaderIsosComponent,
    HeaderExtranetAccessComponent,
    HeaderCallbackComponent,    
    FooterComponent,
    NavbarExtranetComponent
  ]
})
export class SharedModule { }
