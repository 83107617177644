<h1>Si ha dado positivo por Coronavirus, Gar-San puede desinfectar la instalación</h1>
<h2>Desinfección de superficies y aéreas mediante nebulización</h2>
<img src="/assets/images/pages/foto_virus.png" class="floatright" width='20%'>
<p>Los coronavirus son una familia de virus que se conoce desde los años 60 con origen desconocido, existen diferentes tipos que provocan desde resfriado hasta síndrome respiratorio grave (neumonía). La variante detectada a finales de diciembre de 2019 se
    detectó en Whan (china) propagándose al resto de países. Esta variante tiene una tasa de letalidad inferior al SARS de 2002 pero con mayor transmisión.</p>
<h3>Transmisión:</h3>
<p>Mediante microgotas respiratorias que se producen como resultado de toser, estornudar o hablar.</p>
<h3>Síntomas:</h3>
<p>Tos, Dolor de garganta, Fiebre, Respiración con dificultad, dolor de cabeza, escalofríos, goteo nasal. (El tener alguno o todos los síntomas no implica estar contagiado aunque sí es motivo de avisar a los servicios de salud).</p>
<h3>Prevención:</h3>
<p>Actualmente no se ha desarrollado la vacuna ni tiene tratamiento específico, por lo que hay que extremar las medidas preventivas como higiene básica, lavarse las manos cada vez que toquemos algo y evitar contacto con personas infectadas. El uso de mascarillas
    no es útil, solamente es imprescindible si se va a estar en presencia de personas ya infectadas o para los propios enfermos, para evitar la dispersión.</p>
<h3>Tratamiento preventivo en instalaciones:</h3>
<p>No existe tratamiento preventivo que se pueda realizar en ninguna instalación pública o privada.</p>
<h3>Tratamiento curativo en instalaciones:</h3>
<p>En caso de detectar un caso de un paciente con positivo en Coronavirus, la principal medida es aislarlo del resto de personas de su entorno y proceder a una desinfección de las superficies a las que pudiera haber tenido contacto el paciente.</p>
<ul>
    <li>Tratamiento mediante Hipoclorito sódico: se realizaría una desinfección mediante paño empapado en lejía, sobre las superficies a tratar usando EPI’s adecuados contra riesgo biológico, se dejaría actuar durante al menos 15 minutos y al finalizar habría
        que realizar una limpieza y procesar los EPIS desechables con una empresa de recogida de residuos.</li>
    <li>Tratamiento mediante desinfectantes profesionales: son productos testeados contra este tipo de virus aplicados por nebulización / pulverización o contacto con superficies por una empresa profesional de control de plagas. Tras la aplicación hay que
        dejar pasar tres horas de plazo de seguridad y luego ventilar adecuadamente para que no haya restos del producto en el ambiente. Una vez tratado tienen que limpiarse todas las superficies tratadas, aunque ya no sería necesario que usasen EPI’s
        de prevención de riesgo biológico.</li>
</ul>
<h3>Validez del tratamiento: 0 horas</h3>
<p>El tratamiento sólo es efectivo eliminando los virus que pudiese haber en ese momento, si una persona infectada entrase en las instalaciones a continuación habría que volver a realizarlo.</p>