import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import Swal from 'sweetalert2';
import { UsuarioService } from '../../services/usuario.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: [],
})
export class LoginComponent {
  miForm: FormGroup = this.fb.group({
    email: [''], // , [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(1)]],
  });

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private usuarioService: UsuarioService
  ) {}

  login(): void {
    const { email, password } = this.miForm.value;

    this.usuarioService.login({user: email, pass: password, remember: false}).subscribe((res) => {
      if (res.Resultado === 'OK') {
        this.router.navigateByUrl('/extranet/dashboard');
      } else {
        Swal.fire('Error', 'No se ha podido hacer Login, compruebe si su usuario y contraseñas son válidos', 'error');
      }
    });
  }
}
