import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit{


  cookieValue = 'UNKNOWN';
  visible = true;

  @Output()
  closeRgpd = new EventEmitter<any>();

  constructor(private cookieService: CookieService) {
  }

  ngOnInit(): void {
    this.cookieValue = this.cookieService.get('Cookie');

    if (this.cookieValue === 'RGPD'){
      this.visible = false;
    }
  }

  onGRDP(): void {
    this.visible = !this.visible;
    this.cookieService.set( 'Cookie', 'RGPD' );
    if (this.visible) {
      this.closeRgpd.emit(null);
    }
  }

}
