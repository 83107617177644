import { Component} from '@angular/core';

@Component({
  selector: 'app-header-isos',
  templateUrl: './header_isos.component.html',
  styleUrls: ['./header_isos.component.css']
})
export class HeaderIsosComponent {

  constructor( 
    ) {
  }

}
