<h1>GAR-SAN página de contacto</h1>
<section id="llamada">
    <h2>Quiero que me llamen</h2>
    <p>Indíquenos su nombre y teléfono y le llamaremos a la mayor brevedad posible en nuestro horario de oficina:</p>
    <div class="row">
        <div class="col-sm-3 col-1"></div>
        <div class="col-sm-6 col-10">
            <div class="card">
                <div class="card-body">
                    <form class="form-horizontal form-material" autocomplete="off" [formGroup]="llamadaForm" (ngSubmit)="llamada(llamadaForm.value)" id="llamadaForm">
                        <p>Nombre de contacto:</p>
                        <div class="form-group">
                            <input class="form-control" type="text" placeholder="Nombre" formControlName="nombre">
                        </div>
                        <p class="mt-5">Teléfono al que le podemos llamar:</p>
                        <div class="form-group">
                            <input class="form-control" type="text" placeholder="Teléfono" formControlName="telefono">
                        </div>
                        <div class="checkbox  checkbox-primary pull-left mt-5">
                            <input id="checkbox-signup" type="checkbox" class="filled-in" formControlName="acepto_condiciones">
                            <label for="checkbox-signup">&nbsp;He leído y acepto la <a routerLink="/empresa/politica-privacidad">Política de privacidad</a></label>
                        </div>
                        <div>
                            <p class="informacion_politica_datos">INFORMACIÓN BÁSICA SOBRE PROTECCIÓN DE DATOS:</p>
                            <P class="informacion_politica_datos">Responsable Del Tratamiento: GAR-SAN CONTROL DE PLAGAS, S.L.</P>
                            <p class="informacion_politica_datos">Finalidad: Tramitación y gestión de consultas.</p>
                            <p class="informacion_politica_datos">Legitimación: Consentimiento del interesado.</p>
                            <p class="informacion_politica_datos">Derechos: Acceso, rectificación, supresión, limitación del tratamiento, oposición, portabilidad de datos.</p>
                            <p class="informacion_politica_datos">Información adicional: Disponible la información adicional y detallada sobre protección de datos en nuestro sitio web corporativo.</p>
                        </div>
                        <div class="form-group text-center mt-5 mb-5">
                            <div class="col-xs-12">
                                <button class="btn btn-info btn-lg btn-block text-uppercase btn-rounded" type="submit">Solicitar llamada</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-sm-3 col-1"></div>
    </div>
</section>
<section id="contacto">
    <h2>Datos de contacto</h2>
    <div class="row mb-5">
        <div class="col-3"></div>
        <div class="col">
            <p>GAR-SAN Control de plagas, S.L.<br> C/ Pintor Sorolla, 28<br> 03550 Sant Joan d'Alacant<br> (Alicante)<br><a [href]="'tel:965658302'">96.565.83.02</a> ( L-V 09:00-15:00 )<br> ddd@gar-san.com</p>
        </div>
        <div class="col-3 "></div>
    </div>
</section>