import { Component, HostListener, OnInit } from '@angular/core';
import { UsuarioService } from '../../services/usuario.service';
import { Usuario } from '../../models/usuario.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-logo',
  templateUrl: './header_logo.component.html',
  styleUrls: ['./header_logo.component.css']
})
export class HeaderLogoComponent {

  constructor( 
    ) {
  }

}
