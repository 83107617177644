import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { LoginForm } from '../interfaces/login-form.interface';

import { Usuario } from '../models/usuario.model';

const base_url = environment.base_url;

declare const gapi: any;

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  public auth2: any;
  public usuario: Usuario = new Usuario('', '');

  constructor(  private http: HttpClient,
                private router: Router,
                private ngZone: NgZone ) {

    // this.googleInit();
  }

  get token(): string {
    return localStorage.getItem('token') || '';
  }

  get id(): number {
    return Number(localStorage.getItem('id')) || 0;
  }

  get headers(): any  {
    return {
      headers: {
        'x-token': this.token
      }
    };
  }

  login( formData: LoginForm ): Observable<any> {

    const postData = {
      Filtros: '',
      Objeto: formData.user + ':' + formData.pass,
      Operacion: 'Servicios.Extranet.Login.Entrar',
      Token: ''
    };

    return this.http.post(`${ base_url }/api/extranet`, postData )
      .pipe(
        tap( (resp: any) => {
          if (resp.Resultado === 'OK') {
            const { eMail, Nombre, tokenExtranet, idUsuarioExtranet, idCliente, idClienteFacturacion, ZonaCliente, idClienteServicio, idIntermediario } = resp.Objeto;
            this.usuario = new Usuario( Nombre, eMail, '', tokenExtranet, idUsuarioExtranet, idCliente, idClienteFacturacion, ZonaCliente, idClienteServicio, idIntermediario);
            this.guardarLocalStorage( this.usuario.token ?? '', this.usuario.id ?? 0);
            return true;
          } else {
            return false;
          }
        })
      );

  }

  logout(): void {
    this.usuario = new Usuario('', '');
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    this.router.navigateByUrl('/empresa/login');
  }

  validarToken(): Observable<boolean> {

    if (this.token === '' || this.token === undefined) {

      return of(false);
    } else {
      
      const requestBody = {
        Filtros: '',
        Objeto: this.id,
        Operacion: 'Servicios.Extranet.Login.Valide',
        Token: ''
      };

      const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', this.token);
      
      return this.http.post(`${ base_url }/api/extranet`, requestBody, {headers: httpHeaders}).pipe(
        map( (resp: any) => {
          const { eMail, Nombre, idUsuarioExtranet, idCliente, idClienteFacturacion, zonaCliente, idClienteServicio, idIntermediario } = resp.Objeto;
          this.usuario = new Usuario( Nombre, eMail, '', this.token, idUsuarioExtranet, idCliente, idClienteFacturacion, zonaCliente, idClienteServicio, idIntermediario);

          this.guardarLocalStorage( this.token, idUsuarioExtranet );

          return true;
        }),
        catchError( error => of(false) )
      );
    }
  }

  guardarLocalStorage( token: string, id: number): void {
    localStorage.setItem('token', token );
    localStorage.setItem('id', id.toString() );
  }

}
