import { Component} from '@angular/core';

@Component({
  selector: 'app-header-contact-data',
  templateUrl: './header_contact_data.component.html',
  styleUrls: ['./header_contact_data.component.css']
})
export class HeaderContactDataComponent {

  constructor( 
    ) {
  }

}
