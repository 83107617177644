import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modulos
import { SharedModule } from '../shared/shared.module';
import { ComponentsModule } from '../components/components.module';
import { DashboardComponent } from './dashboard/dashboard.component';

import { PipesModule } from '../pipes/pipes.module';

import { ExtranetComponent } from './extranet.component';

import { TreeModule } from '@circlon/angular-tree-component';
import { DocumentosClienteComponent } from './documentos-cliente/documentos-cliente.component';
import { DocumentosGarsanComponent } from './documentos-garsan/documentos-garsan.component';
import { FichasProductoComponent } from './fichas-producto/fichas-producto.component';


@NgModule({
  declarations: [
    ExtranetComponent,
    DashboardComponent,
    DocumentosClienteComponent,
    DocumentosGarsanComponent,
    FichasProductoComponent
  ],
  exports: [
    ExtranetComponent,
    DashboardComponent,
    DocumentosClienteComponent,
    DocumentosGarsanComponent,
    FichasProductoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TreeModule,
    SharedModule,
    RouterModule,
    ComponentsModule,
    PipesModule
  ]
})
export class ExtranetModule { }
