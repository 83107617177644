import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthGuard } from '../guards/auth.guard';

import { ExtranetComponent } from './extranet.component';




const routes: Routes = [
    {
        path: 'extranet',
        component: ExtranetComponent,
        loadChildren: () => import('./child-routes.module').then( m => m.ChildRoutesModule ),
            canActivate: [AuthGuard],
            canLoad: [AuthGuard]
    },

];

@NgModule({
    imports: [ RouterModule.forChild(routes) ],
    exports: [ RouterModule ]
})
export class ExtranetRoutingModule {}


