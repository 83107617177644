import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tratamientos-agua',
  templateUrl: './tratamientos-agua.component.html',
  styleUrls: ['./tratamientos-agua.component.css']
})
export class TratamientosAguaComponent {

  constructor() { }

}
