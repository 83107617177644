<h1>Industria alimentaria</h1>
<section id="industria_alimentaria">
    <h2>Industria alimentaria</h2>
    <p>La Industria Alimentaria está en continua evolución, y Gar-san Sanidad Ambiental ofrece a sus clientes soluciones acordes a las necesidades del momento.</p>
    <p>En estos últimos años los estándares de calidad y seguridad alimentaria <b>BRC</b> (British Retail Consortium) e <b>IFS</b> (International Featured Standar) se han implementado en una gran parte del sector alimentario. Estas normas de calidad y seguridad alimentaria
        son cada vez más exigidas por cadenas de supermercados y receptores de productos finales que se exportan dentro de la UE, y que se preocupan por cumplir con las exigencias de los consumidores finales.</p>
    <p>Gar-san Sanidad Ambiental dentro de su plan de control y sistema de vigilancia implementa en sus clientes de Industria Alimentaria, los requisitos exigidos por estas certificaciones para cumplir con las auditorias pertinentes.</p>
    <div class="row">
        <div class="col-1"></div>
        <div class="col-2">
            <!-- <img src="/assets/images/pages/logo_ifs_food.jpg" height='100'> -->
        </div>
        <div class="col-2">
            <!-- <img src="/assets/images/pages/logo_brc_food.jpg" height='100'> -->
        </div>
        <div class="col"></div>
        <div class="col-2"><img src="/assets/images/pages/foto_alimentos.jpg" height='100'></div>
        <div class="col-1"></div>
    </div>
</section>
<section id="carnicerias">
    <h2>Prácticas correctas de higiene en Carnicerías y afines</h2>
    <p>Acorde a los reglamentos Europeos CE nº 852/2004 y 853/2004 para el cumplimiento de los requisitos en establecimientos con alimentos de origen animal, GAR-SAN, puede solucionar sus necesidades para la implantación, desarrollo y requisitos solicitados
        por las Autoridades Sanitarias según la guía de prácticas correctas de higiene en los establecimientos de elaboración y comercio minorista de la carne en lo referente al control de:</p>
    <ul>
        <li>Agua</li>
        <li>Limpieza y desinfección</li>
        <li>Plagas</li>
        <li>Formación higiénica y capacitación del personal</li>
        <li>Materias primas y proveedores</li>
        <li>Trazabilidad</li>
        <li>Temperaturas</li>
        <li>Mantenimiento</li>
        <li>Residuos</li>
        <li>Desarrollo y/o mantenimiento de Sistema de Análisis de Peligros y Puntos de Control Críticos (APPCC).</li>
        <li>Análisis de verificación de la limpieza y desinfección.</li>
        <li>Verificación de los equipos de medida.</li>
        <li>Revisión de los resultados de los controles.</li>
        <li>Asesoramiento en la elaboración de fichas técnicas.</li>
        <li>Asistencia en auditorías sanitarias de la administración.</li>
        <li>Revisión y asesoramiento del etiquetado.</li>
    </ul>
    <p>No dude en <a routerLink="/empresa/contacto">contactar</a> con nosotros para ampliar información o resolver cualquier duda que le pueda surgir.</p>
</section>